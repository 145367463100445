+function ($) { "use strict";

    // POSTER DATA-API
    // ===============

    $('.calculator')
        .on('click', '.js-calculator-clear', function(){
            clearForm($(this).closest('form')[0]);
            return false;
        })
        .on('click', '.js-calculator-compute', function(){
            computeForm($(this).closest('form')[0]);
            return false;
        })
        .on('change', '.calculator-field', function(){
            computeField(this);
            return false;
        })
    ;

    function checkNumber(input, min, max, msg)
    {
        msg = msg + " field has invalid data: " + input.value;
        var str = input.value;
        for (var i = 0; i < str.length; i++) {
            var ch = str.substring(i, i + 1)
            if ((ch < "0" || "9" < ch) && ch != '.') {
                alert(msg);
                return false;
            }
        }
        var num = 0 + str
        if (num < min || max < num) {
            alert(msg + " not in range [" + min + ".." + max + "]");
            return false;
        }
        input.value = str;
        return true;
    }


    function computeField(input)
    {
        if (input.value != null && input.value.length != 0)
            input.value = "" + eval(input.value);
        computeForm(input.form);
    }


    function computeForm(form)
    {
        if ((form.payments.value == null || form.payments.value.length == 0) ||
            (form.interest.value == null || form.interest.value.length == 0) ||
            (form.principal.value == null || form.principal.value.length == 0)) {
            return;
        }

        if (!checkNumber(form.payments, 1, 480, "# of payments") ||
            !checkNumber(form.interest, 0, 99, "Interest") ||
            !checkNumber(form.principal, 100, 10000000, "Principal")) {
            form.payment.value = "Invalid";
            return;
        }

        var i = form.interest.value;

        if (i > 1) {
            i = i / 100.0;
            form.interest.value = i;
        }
        i /= 12;

        var pow = 1;
        for (var j = 0; j < form.payments.value; j++)
            pow = pow * (1 + i);
        if(i > 0) {
            form.payment.value = roundNumber((form.principal.value * pow * i) / (pow - 1))
        }else{
            form.payment.value = roundNumber((form.principal.value) / form.payments.value)
        }
    }

    function roundNumber(rnum) {
        var rlength = 2; // The number of decimal places to round to
        if (rnum > 8191 && rnum < 10485) {
            rnum = rnum-5000;
            var newnumber = Math.round(rnum*Math.pow(10,rlength))/Math.pow(10,rlength);
            newnumber = newnumber+5000;
        } else {
            var newnumber = Math.round(rnum*Math.pow(10,rlength))/Math.pow(10,rlength);
        }
        return newnumber;
    }

    function clearForm(form)
    {
        form.payments.value = "";
        form.interest.value = "";
        form.principal.value = "";
    }


}(jQuery);