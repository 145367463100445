+function ($) { "use strict";

    // THUMB GALLERY DATA-API
    // ===============
    $('.thumb-gallery').each(function(i){
        var photoId = 'thumb-gallery-photo' + i;
        var navId     = 'thumb-gallery-nav-' + i;

        var $gallery  = $(this);
        var $photo    = $gallery.find('.thumb-gallery-photo');
        var $nav      = $gallery.find('.thumb-gallery-nav');

        $photo.attr('id', photoId);
        $nav.attr('id', navId);

        $photo.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: '#' + navId
        });
        $nav.slick({
            slidesToShow: 5,
            slidesToScroll: 1,
            asNavFor: '#' + photoId,
            dots: true,
            centerMode: true,
            focusOnSelect: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        dots: false
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        dots: false
                    }
                }
            ]
        });

    });

}(jQuery);